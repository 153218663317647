import React, { useRef } from 'react';
import { appSettings } from '../../helpers/settings';
import useApp from '../../hooks/useApp';
import { HiMail } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Axios from 'axios';
import { FiTwitter } from 'react-icons/fi';
import { FaTelegram } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

function Footer() {
    const { themeMode } = useApp();
    const formRef = useRef();
    const { t } = useTranslation();
    const {
        register,
        resetField,
        handleSubmit,
        formState: { errors },
    } = useForm();

    /* --------------------------------------------- 
          NEWSLETTER SUBSCRIPTION FUNCTION
    --------------------------------------------- */
    async function sendFormData() {
        try {
            await Axios.post(`https://formspree.io/f/${appSettings?.newsletterFormId}`, new FormData(formRef.current), {
                headers: {
                    Accept: 'application/json',
                },
            });
            toast.success(`${t('subscribeThanks')}`);
            resetField('email');
        } catch (error) {
            console.log(error);
            toast.error(`${t('toastError')}`);
        }
    }

    return (
        <footer className='main-footer pt-5'>
            <div className='container pt-5 pb-4'>
                <div className='row gy-4 gx-5 mb-4'>
                    <div className='col-xxl-3 col-lg-6'>
                        <img
                            src={`${themeMode === 'dark' ? appSettings?.logoone : appSettings?.logoone}`}
                            alt={`${appSettings?.brandName}`}
                            width={appSettings.logoWidthone}
                            className='img-fluid mb-3'
                        />
                        <p className='mb-4 text-sm text-muted'>{t('shopBrandDescription')}</p>
                        <ul className='list-unstyled p-0 text-sm'>
                            <li className='d-flex mb-2'>
                                <HiMail className='text-primary me-2' size={20} />
                                <a href='mailto:official@difi.market' className='text-reset'>
                                    official@difi.market
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className='col-xxl-3 col-lg-6'>
                        <h4 className='mb-3'>{t('sitemap')}</h4>
                        <ul className='list-unstyled mb-0 p-0 text-muted'>
                            <li className='mb-2'>
                                <Link className='text-reset' to='/'>
                                    {t('home')}
                                </Link>
                            </li>
                            <li className='mb-2'>
                                <Link className='text-reset' to='/products'>
                                    {t('products')}
                                </Link>
                            </li>
                            <li className='mb-2'>
                                <Link className='text-reset' to='/sellers'>
                                    {t('sellers')}
                                </Link>
                            </li>
                            <li className='mb-2'>
                                <Link className='text-reset' to='/shops'>
                                    {t('shops')}
                                </Link>
                            </li>
                            <li className='mb-2'>
                                <Link className='text-reset' to='/activities'>
                                    {t('activity')}
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='col-xxl-3 col-lg-6'>
                        <h4 className='mb-3'>{t('resources')}</h4>
                        <ul className='list-unstyled mb-0 p-0 text-muted'>
                            <li className='mb-2'>
                                <Link className='text-reset' to='/about'>
                                    {t('aboutUs')}
                                </Link>
                            </li>
                            <li className='mb-2'>
                                <Link className='text-reset' to='/contact'>
                                    {t('contactUs')}
                                </Link>
                            </li>
                            <li className='mb-2'>
                                <Link className='text-reset' to='/terms'>
                                    {t('terms')}
                                </Link>
                            </li>
                            <li className='mb-2'>
                                <Link className='text-reset' to='/faqs'>
                                    {t('faqs')}
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className='col-xxl-3 col-lg-6'>
                        <h4 className='mb-3'>{t('newsletterSignup')}</h4>
                        <p className='mb-4 text-sm text-muted'>{t('newsletterText')}</p>
                        <form className='position-relative' ref={formRef} onSubmit={handleSubmit(sendFormData)}>
                            <input
                                type='email'
                                {...register('email', {
                                    required: {
                                        value: true,
                                        message: t('newsletterErrMsg'),
                                    },
                                })}
                                name='email'
                                placeholder={t('newsletterPlaceholder')}
                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                            />
                            {errors.email && <span className='invalid-feedback'>{errors.email.message}</span>}
                            <button className='btn btn-primary btn-sm mt-2 w-100'>{t('subscribe')}</button>
                        </form>
                    </div>
                </div>
                <h2 className='h3 text-center mb-1'>{t('weAreSocial')}</h2>
                <ul className='list-inline mb-0 text-center'>
                    <li className='list-inline-item'>
                        <a
                            className='social-link bg-hover-primary'
                            rel='noopener noreferrer'
                            href='https://x.com/difimarket'
							 target='_blank'
                        >
                            <FiTwitter size='2rem' style={{ transform: 'translateY(-2px)' }} />
                        </a>
                    </li>
                    <li className='list-inline-item'>
                        <a className='social-link bg-hover-primary' target='_blank' rel='noopener noreferrer' href='https://t.me/difitoken'>
                            <FaTelegram size='2rem' style={{ transform: 'translateY(-2px)' }} />
                        </a>
                    </li>
					</ul>
                <p className='text-muted mb-0 text-center text-sm'>
                    {t('createdBy')}{' '}
                    <a href={appSettings.copyrightsLink} className='text-primary'>
                        {appSettings.copyrightsBrand}
                    </a>{' '}
                    {new Date().getFullYear()}. &copy; {t('copyRights')} | <a href="https://base.org" target="_blank" rel="noopener noreferrer">
    <img 
      src="/base-wordmark.svg" 
      alt="Base" 
      style={{ height: '20px', marginLeft: '5px' }} 
    />
  </a>
                </p>
            </div>
        </footer>
    );
}

export default Footer;
