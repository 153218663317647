import React, { useRef, useState } from 'react';
import { BsMailbox2 } from 'react-icons/bs';
import { useForm } from 'react-hook-form';
import { FaTwitter, FaTelegram } from 'react-icons/fa';
import { IoMail } from 'react-icons/io5';
import { toast } from 'react-toastify';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';

// COMPOENENTS
import PageBanner from '../../components/general/PageBanner';
import { appSettings } from '../../helpers/settings';
import useApp from '../../hooks/useApp';

function ContactUsPage() {
    const { t } = useTranslation();
    const { themeMode } = useApp();
    const [successSubmit, setSuccessSubmit] = useState(false);
    const formRef = useRef();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    /* --------------------------------------------- 
          SENDING CONTACT FORM FUNCTION
    --------------------------------------------- */
    async function sendFormData() {
        try {
            await Axios.post(`https://formspree.io/f/${appSettings?.newsletterFormId}`, new FormData(formRef.current), {
                headers: {
                    Accept: 'application/json',
                },
            });
            toast.success(`${t('contactSuccess')}`);
            setSuccessSubmit(true);
        } catch (error) {
            console.log(error);
            toast.error(`${t('toastError')}`);
        }
    }

    return (
        <>
            <PageBanner heading='Contact Us'></PageBanner>


            <div className={`py-5 ${themeMode === 'light' ? 'bg-light' : 'bg-dark'}`}>
                <div className='container'>
                    <div className='row gy-4 gx-5'>
                        <div className='col-lg-4'>
                            <h2>{t('term30Msg2')}</h2>
                            <p className='text-muted'>{t('multiShopMall')}</p>
                        </div>

                        <div className='col-lg-4'>{t('multiShopDescription')}</div>

                        <div className='col-lg-4'>
                            <ul className='list-unstyled mb-0'>
                                <li className='d-flex align-items-center'>
                                    <div className='outlined-icon me-2'>
                                        <IoMail size={14} className='text-primary' />
                                    </div>
                                    <p className='mb-0'> official@difi.market</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <section className='py-5'>
                <div className='container py-5'>
                    <div className='row g-4'>
                        <div className='col-lg-7 mx-auto'>
                            <div className='text-center mb-4'>
                                <h2>{t('dropUsALine')}</h2>
                                <p className='mb-3 text-muted'>{t('contactForInformation')}</p>
                                <ul className='list-inline mb-5'>
                                    <li className='list-inline-item'>
                                        <a
                                            href='https://X.com/difimarket'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            <FaTwitter size={20} />
                                        </a>
                                    </li>
                                    <li className='list-inline-item'>
                                        <a href='https://t.me/difitoken' target='_blank' rel='noopener noreferrer'>
                                            <FaTelegram size={20} />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className='card mb-0 p-lg-4'>
                                <div className='card-body'>
                                    {successSubmit ? (
                                        <div className='text-center'>
                                            <BsMailbox2 size={50} className='text-primary mb-4' />
                                            <h2>{t('thankYou')}</h2>
                                            <p className='text-muted mb-0'>{t('subscriptionSuccess')}</p>
                                        </div>
                                    ) : (
                                        <form ref={formRef} onSubmit={handleSubmit(sendFormData)}>
                                            <div className='row g-4'>
                                                <div className='col-lg-6'>
                                                    <label className='form-label'>{t('contactName')}</label>
                                                    <input
                                                        type='text'
                                                        placeholder='Enter your name'
                                                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                                        name='name'
                                                        {...register('name', {
                                                            required: {
                                                                value: true,
                                                                message: 'Please enter your name',
                                                            },
                                                        })}
                                                    />
                                                    {errors.name && (
                                                        <span className='invalid-feedback'>{errors.name.message}</span>
                                                    )}
                                                </div>
                                                <div className='col-lg-6'>
                                                    <label className='form-label'>{t('contactEmail')}</label>
                                                    <input
                                                        type='text'
                                                        placeholder='mail@example.com'
                                                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                        {...register('email', {
                                                            required: {
                                                                value: true,
                                                                message: 'Please enter your email',
                                                            },
                                                            pattern: {
                                                                // eslint-disable-next-line no-useless-escape
                                                                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                                                message: 'Enter valid email address',
                                                            },
                                                        })}
                                                    />
                                                    {errors.email && (
                                                        <span className='invalid-feedback'>{errors.email.message}</span>
                                                    )}
                                                </div>
                                                <div className='col-12'>
                                                    <label className='form-label'>{t('contactMsg')}</label>
                                                    <textarea
                                                        rows='7'
                                                        className={`form-control ${errors.message ? 'is-invalid' : ''}`}
                                                        name='message'
                                                        {...register('message', {
                                                            required: {
                                                                value: true,
                                                                message: 'Please enter your message',
                                                            },
                                                        })}
                                                    ></textarea>
                                                    {errors.message && (
                                                        <span className='invalid-feedback'>
                                                            {errors.message.message}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className='col-12'>
                                                    <button className='btn btn-primary w-100' type='submit'>
                                                        {t('sendMsg')}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ContactUsPage;
