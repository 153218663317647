/* -----------------------------------------------------------------------------------------
    THSI FILE CONTAINS THE IMPORTANT WEB3/UI CONFIGURATIONS FOR THE APP
----------------------------------------------------------------------------------------- */
import React from 'react';
import { GiMadScientist } from 'react-icons/gi';
import { BsNewspaper } from 'react-icons/bs';
import { DiWindows, DiApple, DiLinux, DiAndroid, DiOpensource } from 'react-icons/di';
import {
    FaSchool,
    FaBook,
    FaDesktop,
    FaTools,
    FaCode,
    FaShieldVirus,
    FaDatabase,
    FaVideo,
    FaGamepad,
    FaImages,
    FaFileVideo,
} from 'react-icons/fa';

export const appSettings = {
    /* APP LOGO - LOGO FOR THE DARK VERSION  */
    logo: '/logowhite.png',
    logoone: '/logo.png',
    /* APP LOGO - LOGO FOR THE LIGHT VERSION  */
    logoLight: '/logoblack.png',

    /* APP MAIN LOGO WIDTH - CHANGE IT ACCORDING TO YOUR OWN LOGO */
    logoWidth: '64',
    logoWidthone: '64',
    /* APP BRAND NAME - USED IN ALL PAGES & META TAGE - CHANGE TO YOUR OWN BRAND NAME*/
    brandName: 'Digital Finance',

    /* APP BRAND DESCRIPTION - USED IN HOME PAGE & META TAGS - PUT A SENTENCE THAT DESCRIBE YOUR APP*/
    brandDescription: 'Digital Finance (DOB) is a decentralized multi-shop on the Base blockchain, enabling users to buy and sell digital and physical goods with USDC. Featuring store setup, verification, shipping management, and dispute resolution, DOB brings secure, versatile e-commerce to Web3.',

    /* APP AUTHOR - CHANGE IT IF YOU HAVE A LICENSE KEY OR PURCHASE CODE */
    marketplaceAuthor: 'DOB Developer',

    /* COPYRIGHTS BRAND NAME */
    copyrightsBrand: 'Digital Finance',

    /* TEXT IDENTIFIER FOR THE NETWORK WHERE THE APP IS UP AND RUNNING, YOU CAN WRITE WHATEVER YOU WANT */
    activeNetworkName: 'Base Blockchain',

    /* COPYRIGHTS LINK - REPLACE IT IF YOU HAVE A LICENSE KEY OR A PURCHASE CODE */
    copyrightsLink: 'https://difi.market',

    /* THE NETWORK RPC URL WHERE YOUR CONTRACTS ARE DEPOLYED ON, CHECK THE EXACT RPC URL FROM HERE https://chainlist.org */
    // rpcUrl: 'https://bsc-dataseed1.binance.org',
     rpcUrl: 'https://weathered-attentive-voice.base-mainnet.quiknode.pro/c28799c5afb0d54dd559d029f209a0a11e09c2a3',

    /* THE CHAIN ID of NETWORK WHERE YOUR CONTRACTS ARE DEPOLYED ON, GET IT FROM HERE https://chainlist.org */
    // networkId: 56,
    networkId: 8453,

    /* THE BLOCK SCAN EXPLORER WHRE YOU CAN TRACK THE TRANSACTIONS */
    blockExplorerUrls: 'https://basescan.org/',

    /* THE CURRENT APP CURRENCY THAT YOUR APP WILL APPEAR BESIDES EVERY PRICE */
    currency: 'USDC',

    /* THE NATIVE CURRENCY THAT YOUR APP WILL USE FOR GAS FEES */
    nativeCurrency: 'ETH',

    /* IPFS PROJECT ID */
    IPFSProjectID: '2MdiAyEWVLi23yercgf7PPnYWRM',

    /* IPFS PRJECT SECRET KEY */
    IPFSSecret: '0cb9aa4f8c64700f3257e2fd9f047162',

    /* IPFS DEDICATED GATEWAY NAME */
    IPFSGatewaySubdomain: 'difi',

    /* ENDPOINT FOR ASK FOR A FEATURE FORM */
    newsletterFormId: 'moqzjqva',

    /* CONTACT US FORM */
    contactFormId: 'moqzjqva',

    /* MAILJS SERVICE KEY */
    mailJServiceKey: 'service_btez9uh',

    /* MAILJS PUBLIC KEY */
    mailJSPublicKey: 'RGw0fai8-gNovwtUE',

    /* MAILJS TEMPLATE ID KEY */
    mailJSTemplateID: 'template_2gyadvl',

    /* MAILJS VERIFY TEMPLATE ID */
    mailJSVerifyTemplateID: 'template_wp4lnfq',

    orderNorifyEmailTemplate: 'template_8ojohbq',

    /* SHOP VERIFY REQUESTS EMAIL */
    shopVerifyMail: 'official@difi.market',

    /* WALLET CONNECT PROJECT ID */
    wcProjectId: 'ff48c6e479fc5a2c0e81af527dd90fa4',

    /* ADSEND AD CLIENT */
    adsenseAdClient: 'ca-pub-2678760727259403',

    /* ADSEND AD SLOT */
    adsenseAdSlot: '3037896495',

    /* COORDINATES FOR CONTACT US PAGE MAP */
    mapCenter: [45.4641943, 9.1896346],

    /* TOKEN ID FOR GETCHINg TOKEN PRICE */
    tokenId: 'usd-coin',
	
	/* TOKEN ID FOR THE TOP TICKER */
    token2Id: 'usd-coin',

    /* CHANNEL CATEGORIES LIST - ADD OR DELETE FROM THEM AS YOU NEED */
    productCategories: [
        { label: 'Clothing', value: 'Clothing', icon: <BsNewspaper size='2rem' className='text-reset' /> },
        { label: 'Electronics', value: 'Electronics', icon: <BsNewspaper size='2rem' className='text-reset' /> },
        { label: 'Home and Garden', value: 'Home', icon: <BsNewspaper size='2rem' className='text-reset' /> },
        { label: 'Beauty', value: 'Beauty', icon: <BsNewspaper size='2rem' className='text-reset' /> },
        { label: 'Sports and Outdoors', value: 'Sports', icon: <BsNewspaper size='2rem' className='text-reset' /> },
        { label: 'Jewelry and Watches', value: 'Jewelry', icon: <BsNewspaper size='2rem' className='text-reset' /> },
        { label: 'Automotive', value: 'Automotive', icon: <BsNewspaper size='2rem' className='text-reset' /> },
        { label: 'Baby and Toys', value: 'Baby', icon: <BsNewspaper size='2rem' className='text-reset' /> },
        { label: 'Tools and Equipment', value: 'Tools', icon: <BsNewspaper size='2rem' className='text-reset' /> },
        { label: 'Health and Wellness', value: 'Health', icon: <BsNewspaper size='2rem' className='text-reset' /> },
        { label: 'Pet Supplies', value: 'Pet', icon: <BsNewspaper size='2rem' className='text-reset' /> },
        { label: 'Office and Supplies', value: 'Office', icon: <BsNewspaper size='2rem' className='text-reset' /> },
        { label: 'Art and Crafts', value: 'Art', icon: <BsNewspaper size='2rem' className='text-reset' /> },
        { label: 'Food and Beverages', value: 'Food', icon: <BsNewspaper size='2rem' className='text-reset' /> },
        { label: 'Books and Magazines', value: 'Books', icon: <BsNewspaper size='2rem' className='text-reset' /> },
        { label: 'Home and Kitchen', value: 'Home', icon: <BsNewspaper size='2rem' className='text-reset' /> },
        { label: 'Music and Entertainment', value: 'Music', icon: <BsNewspaper size='2rem' className='text-reset' /> },
        { label: 'Gift Items', value: 'Gift', icon: <BsNewspaper size='2rem' className='text-reset' /> },
        { label: 'Collectibles', value: 'Collectibles', icon: <BsNewspaper size='2rem' className='text-reset' /> },
        { label: 'Other', value: 'Other', icon: <BsNewspaper size='2rem' className='text-reset' /> },
        { label: 'Crypto & Mining', value: 'Crypto', icon: <BsNewspaper size='2rem' className='text-reset' /> },
    ],

    /* SHOP CATEGORIES LIST - ADD OR DELETE FROM THEM AS YOU NEED */
    shopCategories: [
        { label: 'Fashion and Apparel', value: 'Fashion', icon: <GiMadScientist size='3.5rem' className='text-primary' /> },
        { label: 'Electronics and Gadgets', value: 'Electronics', icon: <GiMadScientist size='3.5rem' className='text-primary' /> },
        { label: 'Home and Decor', value: 'Home', icon: <GiMadScientist size='3.5rem' className='text-primary' /> },
        { label: 'Beauty and Cosmetics', value: 'Beauty', icon: <GiMadScientist size='3.5rem' className='text-primary' /> },
        { label: 'Sports and Fitness', value: 'Sports', icon: <GiMadScientist size='3.5rem' className='text-primary' /> },
        { label: 'Jewelry and Accessories', value: 'Jewelry', icon: <GiMadScientist size='3.5rem' className='text-primary' /> },
        { label: 'Automotive and Tools', value: 'Automotive', icon: <GiMadScientist size='3.5rem' className='text-primary' /> },
        { label: 'Baby and Kids', value: 'Baby', icon: <GiMadScientist size='3.5rem' className='text-primary' /> },
        { label: 'Health and Wellness', value: 'Health', icon: <GiMadScientist size='3.5rem' className='text-primary' /> },
        { label: 'Pet Supplies', value: 'Pet', icon: <GiMadScientist size='3.5rem' className='text-primary' /> },
        { label: 'Books and Stationery', value: 'Books', icon: <GiMadScientist size='3.5rem' className='text-primary' /> },
        { label: 'Art and Crafts', value: 'Art', icon: <GiMadScientist size='3.5rem' className='text-primary' /> },
        { label: 'Food and Beverages', value: 'Food', icon: <GiMadScientist size='3.5rem' className='text-primary' /> },
        { label: 'Music and Entertainment', value: 'Music', icon: <GiMadScientist size='3.5rem' className='text-primary' /> },
        { label: 'Gifts and Collectibles', value: 'Gifts', icon: <GiMadScientist size='3.5rem' className='text-primary' /> },
        { label: 'Home Appliances', value: 'Home', icon: <GiMadScientist size='3.5rem' className='text-primary' /> },
        { label: 'Outdoor and Adventure', value: 'Outdoor', icon: <GiMadScientist size='3.5rem' className='text-primary' /> },
        { label: 'Office and Business Supplies', value: 'Office', icon: <GiMadScientist size='3.5rem' className='text-primary' /> },
        { label: 'Furniture and Furnishings', value: 'Furniture', icon: <GiMadScientist size='3.5rem' className='text-primary' /> },
        { label: 'Miscellaneous', value: 'Miscellaneous', icon: <GiMadScientist size='3.5rem' className='text-primary' /> },
        { label: 'CryptoCurrency', value: 'CryptoCurrency', icon: <GiMadScientist size='3.5rem' className='text-primary' /> },
    ],

    conditionOptions: [
        { label: 'New', value: 'New' },
        { label: 'Like New', value: 'LikeNew' },
        { label: 'Excellent', value: 'Excellent' },
        { label: 'Very Good', value: 'VeryGood' },
        { label: 'Good', value: 'Good' },
        { label: 'Fair', value: 'Fair' },
        { label: 'Used', value: 'Used' },
        { label: 'Refurbished', value: 'Refurbished' },
        { label: 'Open Box', value: 'OpenBox' },
        { label: 'Damaged', value: 'Damaged' },
        { label: 'For Parts', value: 'ForParts' },
    ],

    shippingOptions: [
        { label: 'Europe', value: 'europe' },
        { label: 'Asia', value: 'asia' },
        { label: 'Africa', value: 'africa' },
        { label: 'North America', value: 'north_america' },
        { label: 'South America', value: 'south_america' },
        { label: 'Antarctica', value: 'antarctica' },
        { label: 'Australia', value: 'australia' },
    ],

    /* COMPATIBILITY LIST - ADD OR DELETE FROM THEM AS YOU NEED */
    compatibilityOptions: [
        { label: 'Window', value: 'Windows', icon: <DiWindows size='3.5rem' className='text-primary' /> },
        { label: 'macOS', value: 'macOS', icon: <DiApple size='3.5rem' className='text-primary' /> },
        { label: 'Linux', value: 'Linux', icon: <DiLinux size='3.5rem' className='text-primary' /> },
        { label: 'Android', value: 'Android', icon: <DiAndroid size='3.5rem' className='text-primary' /> },
        { label: 'IOS', value: 'IOS', icon: <DiApple size='3.5rem' className='text-primary' /> },
    ],

    /* CATEGORY LIST - ADD OR DELETE FROM THEM AS YOU NEED */
    categoryOptions: [
        {
            label: 'Utility',
            translationKey: 'utility',
            value: 'Utility',
            icon: <FaTools size='2rem' className='text-reset' />,
        },
        {
            label: 'Education Materials',
            translationKey: 'education',
            value: 'Education',
            icon: <FaSchool size='2rem' className='text-reset' />,
        },
        {
            label: 'eBooks',
            translationKey: 'ebooks',
            value: 'eBooks',
            icon: <FaBook size='2rem' className='text-reset' />,
        },
        {
            label: 'Graphic design',
            translationKey: 'gDesign',
            value: 'Graphic design',
            icon: <FaImages size='2rem' className='text-reset' />,
        },
        {
            label: 'Operating systems',
            translationKey: 'os',
            value: 'Operating systems',
            icon: <FaDesktop size='2rem' className='text-reset' />,
        },
        {
            label: 'Programming',
            translationKey: 'programming',
            value: 'Programming',
            icon: <FaCode size='2rem' className='text-reset' />,
        },
        {
            label: 'Antivirus and security',
            translationKey: 'antiVirus',
            value: 'Antivirus and security',
            icon: <FaShieldVirus size='2rem' className='text-reset' />,
        },
        {
            label: 'Database',
            translationKey: 'db',
            value: 'Database',
            icon: <FaDatabase size='2rem' className='text-reset' />,
        },
        {
            label: 'Multimedia',
            translationKey: 'mutimedia',
            value: 'Multimedia',
            icon: <FaVideo size='2rem' className='text-reset' />,
        },
        {
            label: 'Gaming',
            translationKey: 'gaming',
            value: 'Gaming',
            icon: <FaGamepad size='2rem' className='text-reset' />,
        },
        {
            label: 'Open Source',
            translationKey: 'openSource',
            value: 'Open Source',
            icon: <DiOpensource size='2rem' className='text-reset' />,
        },
        {
            label: 'Adults 18+',
            translationKey: 'adult',
            value: 'Adults 18+',
            icon: <FaFileVideo size='2rem' className='text-reset' />,
        },
    ],
};
