import React from 'react';
import useApp from '../../hooks/useApp';
import Langs from './Langs';
import { Link } from 'react-router-dom';
import { FiTwitter } from 'react-icons/fi';
import { FaTelegram } from 'react-icons/fa';
import ToggleThemeHandler from './ToggleThemeHandler';
import { useTranslation } from 'react-i18next';

function TopBanner() {
    const { t } = useTranslation();
    const { themeMode, usdToken } = useApp();

    return (
        <>
            <div
                className={`${themeMode === 'light' ? 'bg-dark text-light' : 'bg-dark'} py-1 position-relative`}
                style={{ zIndex: 1001 }}
            >
                <div className='container pb-2 pb-lg-0'>
                    <div className='row g-0 align-items-center'>
                        <div className='col-lg-4'>
                            <ul className='list-inline p-0 mb-0 d-flex justify-content-center justify-content-lg-start align-items-center'>
                                <li className='list-inline-item'>
                                    <Langs />
                                </li>
                                <li
                                    className={`list-inline-item pe-2 ${
                                        themeMode === 'light' ? 'text-gray-400' : 'text-gray-700'
                                    }`}
                                >
                                    |
                                </li>
                                <li className='list-inline-item'>
                                    <a className='text-reset' target='_blank' rel='noopener noreferrer' href='https://x.com/difimarket'>
                                        <FiTwitter size='1rem' style={{ transform: 'translateY(-2px)' }} />
                                    </a>
                                </li>
                                <li className='list-inline-item'>
                                    <a className='text-reset' target='_blank' rel='noopener noreferrer' href='https://t.me/difitoken'>
                                        <FaTelegram size='1rem' style={{ transform: 'translateY(-2px)' }} />
                                    </a>
                                </li>
								<li className='list-inline-item'>
                                    <ToggleThemeHandler />
                                </li>
                            </ul>
                        </div>

                        <div className='col-lg-4 text-center d-none d-lg-block'>
                            <div className='d-inline-block'>
                                <a
                                    href='https://www.coingecko.com/en/coins/ethereum'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='text-xs d-flex align-items-center justify-content-center text-reset'
                                >
                                    <div className='animated-bullet me-2'>
                                        <div className='animated-bullet-inner'></div>
                                    </div>
                                    Live <img src='/token.png' alt='ETH' width='20' className='flex-shrink-0 mx-1' />{' '}
                                    price is{' '}
                                    <strong className='fw-bold text-success ms-2'>
                                        {(1 / usdToken).toFixed(2)} USD
                                    </strong>
                                </a>
                            </div>
                        </div>

                        <div className='col-lg-4 text-center text-lg-end'>
                            <ul className='list-inline mb-0 p-0 text-sm'>
                                <li className='list-inline-item'>
                                    <Link className='text-reset' to='/about'>
                                        {t('aboutUs')}
                                    </Link>
                                </li>
                                <li
                                    className={`list-inline-item ${
                                        themeMode === 'light' ? 'text-gray-400' : 'text-gray-700'
                                    }`}
                                >
                                    |
                                </li>
                                <li className='list-inline-item'>
                                    <Link className='text-reset' to='/faqs'>
                                        {t('faqs')}
                                    </Link>
                                </li>
                                <li
                                    className={`list-inline-item ${
                                        themeMode === 'light' ? 'text-gray-400' : 'text-gray-700'
                                    }`}
                                >
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TopBanner;
